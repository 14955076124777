<template>
  <div id="terms">
    <b-card class="content">
      <b-card-header>Terms of Service</b-card-header>
      <b-card-body class="content-body ">
        <TermsAndConditions></TermsAndConditions>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>

import TermsAndConditions from '@/components/TermsAndConditions'
export default {
  name: 'terms-and-conditions-view',
  components: {
    TermsAndConditions
  },
  created () {
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

#terms {
  margin: 0;
  display: flex;
  flex-direction: row-reverse;
  width: 100vw;
  height: 100vh;
  font-family: 'Open Sans', sans-serif;
  align-items: center;
  justify-content: center;
  background: url('../assets/Satellite-View.png') black;
  background-size: cover;
  background-repeat: no-repeat;
}

.content {
  display: flex;
  text-align: center;
  color: $text-color-invert;
  background-color: $theme-color-background-2;
  width: 80%;
  height: 90%;
  overflow: hidden;
}

.content-body {
  overflow: auto;
  height: 95%;
}

p {
  text-align: left;
}
li {
  text-align: left;
}
</style>
