// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/Satellite-View.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/*\n  SCSS Variables Files\n\n  This file should be imported to all components that need to access the SCSS Variables for themeing\n  It *SHOULD NOT* contain any CSS Rules. Only Variables.\n  Global Styles should be added to the custom.scss file, which is imported globally and applied to all components\n  automatically.\n */\n#terms[data-v-655c1786] {\n  margin: 0;\n  display: flex;\n  flex-direction: row-reverse;\n  width: 100vw;\n  height: 100vh;\n  font-family: \"Open Sans\", sans-serif;\n  align-items: center;\n  justify-content: center;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") black;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n.content[data-v-655c1786] {\n  display: flex;\n  text-align: center;\n  color: #e5e5e5;\n  background-color: #2a2a2a;\n  width: 80%;\n  height: 90%;\n  overflow: hidden;\n}\n.content-body[data-v-655c1786] {\n  overflow: auto;\n  height: 95%;\n}\np[data-v-655c1786] {\n  text-align: left;\n}\nli[data-v-655c1786] {\n  text-align: left;\n}", ""]);
// Exports
module.exports = exports;
