var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "terms" } },
    [
      _c(
        "b-card",
        { staticClass: "content" },
        [
          _c("b-card-header", [_vm._v("Terms of Service")]),
          _c(
            "b-card-body",
            { staticClass: "content-body" },
            [_c("TermsAndConditions")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }